import { FC } from 'react';
import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { useButton, Props } from './useButton';
import styles from './Button.module.css';

export const Button: FC<Props> = (props) => {
  const { to, href, children, color, block, disabled, onClick } =
  useButton(props);
  const classes = clsx(
    styles.button,
    color === 'primary' && styles.primary,
    color === 'danger' && styles.danger,
    block && styles.full
  );

  if (to) {
    return (
      <Link to={to} className={classes}>
        {children}
      </Link>);

  }

  if (href) {
    return (
      <a href={href} className={classes}>
        {children}
      </a>);

  }

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {children}
    </button>);

};