import { ReactNode, SyntheticEvent } from 'react'

export interface Props {
  to?: string
  href?: string
  onClick?: () => void
  children: ReactNode
  color?: 'primary' | 'danger'
  block?: boolean
  disabled?: boolean
}

export const useButton = (props: Props) => {
  const { to, href, children, color, block, disabled = false } = props
  const onClick = (e: SyntheticEvent) => {
    if (props.onClick) {
      e.preventDefault()
      props.onClick()
    }
  }

  return {
    to,
    href,
    onClick,
    children,
    color,
    block,
    disabled,
  }
}
